















import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import AreaEditTabInfo from "./AreaEditTabInfo.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  IResponseArea,
  ICity,
} from "@core/services/interfaces/covarege-area/area/IAreaService";
import { Ref, ref } from "@vue/composition-api";
import { IAreaLocal } from "../interfaces/IArea";

@Component({
  name: "AreaEdit",
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    AreaEditTabInfo,
  },
})
export default class AreaEdit extends Vue {
  areaId: string | null = null;
  areaBlank: IAreaLocal = {
    id: "",
    name: "",
    code: "",
    region: 0,
    cities: [],
  };
  areaLocal: Ref<IAreaLocal> = ref(this.areaBlank);

  // Lifecycle
  created() {
    this.areaId = this.$route.params.id;
    this.$store
      .dispatch("admin-area/fetchGetAreaById", this.areaId)
      .then((response: { data: IResponseArea }) => {
        const data = response.data.Data;
        this.areaLocal.value = {
          id: data.Id,
          name: data.Nome,
          code: data.Codigo,
          region: data.RegiaoOperacionalId,
          cities: data.Municipios.map((city: ICity) => ({
            id: city.Id,
            name: city.Nome,
            codeSerpro: city.CodigoSerpro,
            regionId: city.RegiaoOperacionalId,
            region: {
              id: city.RegiaoOperacional?.Id,
              name: city.RegiaoOperacional?.Nome,
              status: city.RegiaoOperacional?.Status,
              author: city.RegiaoOperacional?.Autor,
            },
          })),
        };
      });
  }
}
