










































































































import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { AvButton } from "@/components";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { Ref, ref } from "@vue/composition-api";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Component, Prop, Vue } from "vue-property-decorator";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  IResponseGetRegionActive,
  IRegionApi,
} from "@core/services/interfaces/covarege-area/region/IRegionService";
import { IAreaLocal } from "../interfaces/IArea";
import AreaEditMun from "./AreaEditMun.vue";

@Component({
  name: "AreaEditTabInfo",
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BFormInvalidFeedback,
    vSelect,
    AvButton,
    AreaEditMun,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
})
export default class AreaEditTabInfo extends Vue {
  @Prop({ required: true }) area!: Ref<IAreaLocal>;

  // Data
  required = required;
  loading: Ref<boolean> = ref(false);
  getting: Ref<boolean> = ref(false);
  tableHas: Ref<Array<any>> = ref([]);
  tableNoHas: Ref<Array<any>> = ref([]);

  blankData: IAreaLocal = {
    id: "",
    name: "",
    code: "",
    region: 0,
    cities: [],
  };
  regionOptions: { label: string; value: number | undefined }[] = [];

  resetData() {
    this.area.value = JSON.parse(JSON.stringify(this.blankData));
  }

  formValidation = formValidation(this.resetData);

  // Computeds
  get isLoading(): boolean {
    return this.loading.value;
  }

  get isGetting(): boolean {
    return this.getting.value;
  }

  // LifeCicly
  created() {
    this.getting.value = true;
    this.$store
      .dispatch("admin-region/fetchGetRegionActives")
      .then((response: IResponseGetRegionActive) => {
        this.regionOptions = response.data.Data.map((region: IRegionApi) => ({
          label: region.Nome,
          value: region.Id,
        }));
      })
      .finally(() => {
        this.getting.value = false;
      });
  }

  // Methods
  backList() {
    this.$router.push({ name: "admin-panel-area-list" });
  }

  onSubmit(area: Ref<IAreaLocal>) {
    this.loading.value = true;
    this.$store
      .dispatch("admin-area/fetchEditArea", {
        id: area.value.id,
        nome: area.value.name,
        regiaoOperacionalId: area.value.region,
        codigo: area.value.code,
        municipiosIds: this.tableHas.value.map((item) => item.id),
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Área Operacional atualizada com sucesso!",
            icon: "CheckIcon",
            variant: "success",
          },
        });

        this.$router.push({ name: "admin-panel-area-list" });
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao atualizar área operacional!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        this.loading.value = false;
      });
  }

  rediceValue = (option: { label: string; value: number }): number => {
    return option.value;
  };
}
