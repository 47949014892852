

































































































































































































































































































































import {
  BRow,
  BCol,
  BCollapse,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BCard,
  BTable,
  BPagination,
  BLink,
  BInputGroup,
  BInputGroupAppend,
  VBToggle,
} from "bootstrap-vue";
import { ref, Ref } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import useAreaEdit from "./useAreaEdit";
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import { AvCheckbox } from "@/components";
import { AvSkeletonTable, AvSkeletonInput } from "@/components/av-skeleton";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// Services
import { useState, useArea } from "@/services";

// Interfaces
import { IAreaLocal } from "../interfaces/IArea";
import { IResponseListInfluencedCitiesArea } from "@core/services/interfaces/covarege-area/area/IAreaService";
import {
  IResponseListState,
  IStateApi,
} from "@core/services/interfaces/covarege-area/state/IStateService";
import { IOption } from "@core/services/interfaces/IUtil";
import {
  IRegionApi,
  IResponseGetRegionActive,
} from "@core/services/interfaces/covarege-area/region/IRegionService";

interface IHasItems {
  select: boolean;
  id: number;
  index?: number;
  name: string;
  codeSerpro: number;
  state: string;
  region?: string | null;
  area?: string;
}

interface ITable {
  filter: string | null;
  filterOn: Array<any>;
  filtering?: boolean;
  totalRows: number;
  currentPage: number;
  perPageOptions: number[];
  perPage: number;
  selected: IHasItems[];
  loading: boolean;
}

@Component({
  name: "AreaEditMun",
  components: {
    BRow,
    BCol,
    BCard,
    BCollapse,
    BFormGroup,
    BFormCheckbox,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BTable,
    BPagination,
    BLink,
    BInputGroup,
    AvCheckbox,
    AvSkeletonTable,
    AvSkeletonInput,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
})
export default class AreaEditMun extends Vue {
  @Prop({ required: true }) area!: Ref<IAreaLocal>;
  @Prop({ required: true }) itemsHas!: Ref<IHasItems[]>;
  @Prop({ required: true }) itemsNoHas!: Ref<IHasItems[]>;

  // Data
  $refs = {
    hasItems: {} as any,
  };
  loading: Ref<boolean> = ref(false);
  loadingState = false;
  loadingRegion = false;
  stateOptions: IOption[] = [];
  stateHasFilter: string | null = null;
  stateNoHasFilter: string | null = null;
  searchNoHas: string = "";
  regionOptions: IOption[] = [];
  regionNoHasFilter = "";
  sortBy:
    | "select"
    | "id"
    | "index"
    | "name"
    | "codeSerpro"
    | "state"
    | "region"
    | "area" = "name";
  sortDesc: boolean = false;
  tableHas: ITable = {
    filter: null,
    filterOn: [],
    totalRows: 0,
    currentPage: 1,
    perPageOptions: [10, 25, 50, 100],
    perPage: 10,
    selected: [],
    loading: false,
  };

  tableNoHas: ITable = {
    filter: null,
    filterOn: [],
    filtering: false,
    totalRows: 0,
    currentPage: 1,
    perPageOptions: [10, 25, 50, 100],
    perPage: 10,
    selected: [],
    loading: false,
  };

  useAreaEdit = useAreaEdit();

  // LifeCycle
  created() {
    this.tableHas.loading = true;
    this.tableNoHas.loading = true;

    this.fetchListStates();
    this.fetchListActiveRegions();
    useArea
      .requestListInfluencedCities({
        id: this.$route.params.id,
        influencia: true,
      })
      .then((response: IResponseListInfluencedCitiesArea) => {
        this.itemsHas.value = response.data.Data.map((city) => ({
          select: false,
          id: city.Id,
          name: city.Nome,
          codeSerpro: city.CodigoSerpro,
          region: city.RegiaoOperacionalNome,
          area: city.AreaInfluenciaNome,
          state: city.Uf,
        }));

        this.tableHas.totalRows = this.itemsHas.value.length;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao consultar municípios influenciados!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.tableHas.loading = false;
      });

    useArea
      .requestListInfluencedCities({
        id: this.$route.params.id,
        influencia: false,
      })
      .then((response: IResponseListInfluencedCitiesArea) => {
        this.itemsNoHas.value = response.data.Data.map((city, index) => ({
          select: false,
          id: city.Id,
          index: index,
          name: city.Nome,
          codeSerpro: city.CodigoSerpro,
          region: city.RegiaoOperacionalNome,
          area: city.AreaInfluenciaNome,
          state: city.Uf,
        }));

        const filter = this.filterItems(
          this.itemsNoHas.value,
          this.tableNoHas.currentPage,
          this.tableNoHas.perPage
        );

        this.tableNoHas.filterOn = filter.items;
        this.tableNoHas.totalRows = filter.filtered;
        this.tableNoHas.currentPage = 1;

        this.customSortCompare();
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao consultar municípios NÃO influenciados!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.tableNoHas.loading = false;
      });
  }

  // Computed
  get isLoading(): boolean {
    return this.loading.value;
  }

  get filteredTableHas() {
    if (!this.itemsHas.value) return [];

    return this.itemsHas.value.filter((item) =>
      this.stateHasFilter
        ? this.stateHasFilter == "" || item.state === this.stateHasFilter
        : true
    );
  }

  get dataHasMeta() {
    const localItemsCount =
      this.filteredTableHas.length < this.itemsHas.value.length
        ? this.itemsHas.value.length
        : this.filteredTableHas.length;

    return {
      from:
        this.tableHas.perPage * (this.tableHas.currentPage - 1) +
        (localItemsCount ? 1 : 0),
      to: Math.min(
        this.tableHas.perPage * this.tableHas.currentPage,
        this.tableHas.totalRows
      ),
      of: this.tableHas.totalRows,
      total: localItemsCount,
    };
  }

  get dataNoHasMeta() {
    const localItemsCount = this.tableNoHas.filterOn.length;

    return {
      from:
        this.tableNoHas.perPage * (this.tableNoHas.currentPage - 1) +
        (localItemsCount ? 1 : 0),
      to: Math.min(
        this.tableNoHas.perPage * this.tableNoHas.currentPage,
        this.tableNoHas.totalRows
      ),
      total: this.tableNoHas.totalRows,
    };
  }

  get selectedHas() {
    return this.itemsHas.value.filter((item: IHasItems) => item.select);
  }

  get selectedNoHas() {
    return this.itemsNoHas.value.filter((item: IHasItems) => item.select);
  }

  get filterNoHas() {
    return this.tableNoHas.filterOn;
  }

  // Watch
  @Watch("tableNoHas.currentPage")
  @Watch("tableNoHas.perPage")
  @Watch("tableNoHas.filter")
  @Watch("stateNoHasFilter")
  @Watch("regionNoHasFilter")
  filterItemsNoHas() {
    this.tableNoHas.filtering = true;

    const customFilter = (item: IHasItems): boolean => {
      if (this.stateNoHasFilter && this.regionNoHasFilter) {
        return (
          this.stateNoHasFilter == item.state &&
          this.regionNoHasFilter == item.region
        );
      }

      if (this.stateNoHasFilter) {
        return this.stateNoHasFilter == item.state;
      }

      if (this.regionNoHasFilter) {
        return this.regionNoHasFilter == item.region;
      }

      return true;
    };

    const filter = this.filterItems(
      this.itemsNoHas.value,
      this.tableNoHas.currentPage,
      this.tableNoHas.perPage,
      this.tableNoHas.filter || "",
      customFilter
    );
    this.tableNoHas.filtering = false;

    this.tableNoHas.filterOn = filter.items;
    this.tableNoHas.totalRows = filter.filtered;
  }

  @Watch("sortBy")
  @Watch("sortDesc")
  customSortCompare() {
    const items = this.itemsNoHas.value;

    items.sort((a: IHasItems, b: IHasItems) => {
      const compareString = (
        itemA: string,
        itemB: string,
        sortDesc: boolean
      ): number => {
        if (!sortDesc) {
          return itemA.localeCompare(itemB);
        }

        return itemB.localeCompare(itemA);
      };

      const compareNumber = (
        itemA: number,
        itemB: number,
        sortDesc: boolean
      ): number => {
        if (!sortDesc) {
          return itemA - itemB;
        }

        return itemB - itemA;
      };

      const compareBoolean = (
        itemA: boolean,
        itemB: boolean,
        sortDesc: boolean
      ): number => {
        let responseA = -1;
        let responseB = 1;

        if (sortDesc) {
          responseA = 1;
          responseB = -1;
        }

        if (itemA && !itemB) {
          return responseA; // 'a' vem antes de 'b'
        }
        if (!itemA && itemB) {
          return responseB; // 'b' vem antes de 'a'
        }

        return 0; // mantém a ordem atual
      };

      switch (this.sortBy) {
        case "select":
          return compareBoolean(a.select, b.select, this.sortDesc);
        case "codeSerpro":
          return compareNumber(a.codeSerpro, b.codeSerpro, this.sortDesc);
        case "state":
          return compareString(a.state, b.state, this.sortDesc);
        case "region":
          let regionA = a.region || "";
          let regionB = b.region || "";

          return compareString(regionA, regionB, this.sortDesc);
        case "area":
          let areaA = a.area || "";
          let areaB = b.area || "";

          return compareString(areaA, areaB, this.sortDesc);
        default:
          return compareString(a.name, b.name, this.sortDesc);
      }
    });

    const filter = this.filterItems(
      items,
      this.tableNoHas.currentPage,
      this.tableNoHas.perPage,
      this.tableNoHas.filter || ""
    );
    this.tableNoHas.filtering = false;

    this.tableNoHas.filterOn = filter.items;
    this.tableNoHas.totalRows = filter.filtered;
  }

  // Methods
  filterItems(
    items: Array<any>,
    currentPage: number,
    perPage: number,
    searchQuery: string = "",
    customFilter?: (item: any) => boolean
  ): { total: number; filtered: number; items: Array<any> } {
    // Aplica a filtragem padrão com base na pesquisa e na página atual
    let filteredItems = items.filter((item) => {
      let match = true;
      if (searchQuery != "") {
        const filters = Object.entries(item).filter(([key, value]) => {
          const strValue = "" + value;

          return strValue.toLowerCase().includes(searchQuery.toLowerCase());
        });

        if (filters.length == 0) match = false;
      }

      // Retorna verdadeiro para incluir o item na lista filtrada
      return match;
    });

    // Aplica o filtro personalizado, se fornecido
    if (customFilter) {
      filteredItems = filteredItems.filter(customFilter);
    }

    // Calcula o índice inicial e final dos itens na página atual
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;

    // Retorna apenas os itens da página atual
    return {
      total: items.length,
      filtered: filteredItems.length,
      items: filteredItems.slice(startIndex, endIndex),
    };
  }

  fetchListStates() {
    this.loadingState = true;
    useState
      .requestList({
        paginar: false,
        municipios: [],
      })
      .then((response: IResponseListState) => {
        this.stateOptions = response.data.Data.data.map((state: IStateApi) => ({
          value: state.Sigla,
          label: state.Sigla,
        }));
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar a lista de estados!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingState = false;
      });
  }

  fetchListActiveRegions() {
    this.loadingRegion = true;
    this.$store
      .dispatch("admin-region/fetchGetRegionActives")
      .then((response: IResponseGetRegionActive) => {
        this.regionOptions = response.data.Data.map((region: IRegionApi) => ({
          label: region.Nome,
          value: region.Nome,
        }));
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao consultar as regiões ativas!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingRegion = false;
      });
  }

  backList() {
    this.$router.push({ name: "admin-panel-area-list" });
  }

  @Watch("filteredTableHas")
  onFiltered(items: IHasItems[]) {
    this.tableHas.totalRows = items.length;
    this.tableHas.currentPage = 1;
  }

  onFilteredNoHas(filteredItems: Array<any>) {
    this.tableNoHas.totalRows = filteredItems.length;
    this.tableNoHas.currentPage = 1;
  }

  addCities() {
    const selected = this.selectedNoHas.map((item: IHasItems) => ({
      ...item,
      select: false,
    }));
    this.itemsHas.value = this.itemsHas.value.concat(selected);
    this.itemsNoHas.value = this.itemsNoHas.value.filter(
      (item: IHasItems) => !item.select
    );
    this.tableHas.totalRows = this.itemsHas.value.length;
    this.tableNoHas.totalRows = this.itemsNoHas.value.length;

    this.refreshNoHas();
  }

  removeCities() {
    const selected = this.selectedHas.map((item: IHasItems) => ({
      ...item,
      select: false,
    }));
    this.itemsNoHas.value = this.itemsNoHas.value.concat(selected);
    this.itemsHas.value = this.itemsHas.value.filter(
      (item: IHasItems) => !item.select
    );
    this.tableHas.totalRows = this.itemsHas.value.length;
    this.tableNoHas.totalRows = this.itemsNoHas.value.length;

    this.refreshNoHas();
  }

  refreshNoHas() {
    const filter = this.filterItems(
      this.itemsNoHas.value,
      this.tableNoHas.currentPage,
      this.tableNoHas.perPage
    );

    this.tableNoHas.filterOn = filter.items;
    this.tableNoHas.totalRows = filter.filtered;
    this.tableNoHas.currentPage = 1;
  }

  rediceValue(option: { label: string; value: any }) {
    return option.value;
  }

  filterTable() {
    // Atualiza a tabela toda vez que o select de estado for alterado
    this.$nextTick(() => {
      this.$refs.hasItems.refresh();
    });
  }
}
